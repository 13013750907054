import {Link} from 'gatsby';
import type {PropsWithChildren} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {BottomWave} from './waves';

const Header = (): JSX.Element => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const isScrolledDown = useMemo(() => scrollPosition > 10, [scrollPosition]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed w-full z-30 top-0 text-white ${isScrolledDown && 'bg-white shadow'}`}>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link
            className={`no-underline hover:no-underline font-bold text-2xl lg:text-4xl ${
              isScrolledDown ? 'text-gray-800 hover:text-gray-800' : 'text-white hover:text-white'
            }`}
            to="/"
          >
            Codelity
          </Link>
        </div>
      </div>

      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  );
};

const Footer = (): JSX.Element => {
  return (
    <footer className="bg-white">
      <div className="container mx-auto px-8">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 mb-6 text-black">
            <Link className="no-underline hover:no-underline font-bold text-2xl lg:text-4xl" to="/">
              Codelity
            </Link>
            <p className="mt-2 text-gray-600">
              © Codelity LLC {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>

          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Legal</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a href="#" className="no-underline hover:underline text-gray-800">
                  Terms
                </a>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a href="#" className="no-underline hover:underline text-gray-800">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Layout = ({children}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <div className="leading-normal tracking-normal text-white gradient">
      <Header />
      <div className="pt-24">{children}</div>
      <BottomWave />
      <Footer />
    </div>
  );
};

export default Layout;
