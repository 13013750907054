import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';

type RequiredProps = {
  title: string;
  pathname: string;
};

type DefaultProps = {
  description?: string;
  image?: string;
};

const defaultProps: DefaultProps = {
  description: '',
  image: '',
};

type SeoProps = RequiredProps & DefaultProps;

/**
 * SEO component that use React Helmet for specifying necessary SEO info.
 *
 * Tutorial https://www.gatsbyjs.com/docs/add-seo-component/
 *
 * @param params
 * @returns SEO component.
 */
const SEO = ({description, title, pathname, image}: SeoProps): JSX.Element => {
  const {site} = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          headline
          siteUrl
          author
          defaultImage: image
          siteLanguage
          ogLanguage
          logo
        }
      }
    }
  `);

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      logo,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      defaultImage,
    },
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: seo.url,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: buildTime,
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${logo}`,
    },
  };

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: defaultTitle,
      },
      position: 1,
    },
    {
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    },
  ];
  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  return (
    <>
      <html lang={siteLanguage} />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content={ogLanguage} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={description} />
    </>
  );
};

SEO.defaultProps = defaultProps;

export default SEO;
