import {HeadFC, HeadProps} from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {TopWave} from '../components/waves';

type ProductCardProps = {
  name: string;
  description: string;
  url: string;
};

const ProductCard = ({name, description, url}: ProductCardProps) => {
  const visitButtonClasses =
    'mx-auto lg:mx-0 hover:underline gradient-button text-white hover:text-white font-bold rounded-2xl py-4 px-8 shadow-lg';
  return (
    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
      <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
        <a href={url} className="flex flex-wrap no-underline hover:no-underline">
          <div className="w-full font-bold text-xl text-gray-700 px-6 mt-4">{name}</div>
          <p className="text-gray-800 text-base px-6 mb-5">{description}</p>
        </a>
      </div>
      {url && (
        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
          <div className="flex items-center justify-start">
            <a href={url} className={visitButtonClasses}>
              Visit
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p className="hidden uppercase w-full">What business are you?</p>
          <h1 className="my-4 text-5xl font-bold leading-tight">Modern Cloud Applications</h1>
          <p className="leading-normal text-2xl mb-8">Web and mobile apps for work and fun</p>
        </div>
        <div className="hidden w-full md:w-3/5 py-6 text-center">
          {/* <img className="w-full md:w-4/5 z-50" src="hero.png" /> */}
        </div>
      </div>

      <div className="relative -mt-12 lg:-mt-24">
        <TopWave />
      </div>

      <section className="bg-white py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Our Products
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <ProductCard
            name="TravelFreedom.io"
            description="Check your travel freedom! Passport rankings (ordinary, official, diplomatic,
                  national IDs), destinations, visa policies/requirements, travel freedom maps."
            url="https://www.travelfreedom.io"
          />

          <ProductCard name="Coming soon!" description="" url="" />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({}: HeadProps<unknown>) => {
  return <SEO title="Codelity LLC" pathname="/" />;
};
